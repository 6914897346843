@font-face {
  font-family: 'Roboto Regular';
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
    src: url("./assets/fonts/Roboto-Bold.ttf");
    font-weight: 500;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 16px;
}

body {
    height: 100%;
    min-height: 100%;
    font-family: 'Roboto Regular', Arial, sans-serif;
}

label, button, a, *:focus {
    -webkit-tap-highlight-color: transparent;
    appearance: none;
}

#root {
    height: 100%;
    min-height: 100%;
    background-color: #686868;
    overflow: hidden;
}

 /*For remove auto-inserting iframe by react-scripts, don't forget comment this before release*/
/*iframe {*/
/*    display: none;*/
/*}*/